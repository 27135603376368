import { StorageValue } from 'shared/utils/storage'

import type { Router } from 'next/router'

type ParsedUrlQuery = Router['query']

const disallowedKeys = ['token', 'spush']

export const sanitizeQueryParams = (queryObj: ParsedUrlQuery) => {
  return Object.keys(queryObj).reduce((acc, key) => {
    if (!disallowedKeys.includes(key)) {
      acc[key] = queryObj[key]
    }

    return acc
  }, {} as ParsedUrlQuery)
}

export const DISABLE_ANALYTIC_QUERY_KEY = 'sda'
export const DISABLE_ANALYTIC_STORAGE_KEY = `frr-${DISABLE_ANALYTIC_QUERY_KEY}`

export const disableAnalyticFlagStorage = new StorageValue({
  key: DISABLE_ANALYTIC_STORAGE_KEY,
  storage: global.window?.sessionStorage,
})

export const getInitialShouldBeDisabled = () => {
  if (!global.window) {
    return false
  }

  const urlParams = new URLSearchParams(global.window.location.search)
  const value =
    disableAnalyticFlagStorage.get() === '1' || urlParams.get(DISABLE_ANALYTIC_QUERY_KEY)

  if (value) {
    disableAnalyticFlagStorage.set('1')
  }

  return value
}
