import { screensConfig } from './screens'

export const startScreens = [
  /* PLOP_START_SCREENS */
  screensConfig.start_gender_chakra,
  screensConfig.start_food_relationship,
  screensConfig.start_age_chakra,
  screensConfig.start_age,
]

export const startScreensIds = startScreens.map(({ id }) => id)
