import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { withCoreProviders } from 'shared/components/App/hocs'
import { AppOnboarding } from 'shared/components/AppOnboarding'
import { SplashScreen } from 'shared/components/SplashScreen'

import { useDocumentSocialWebViewBrowser } from 'shared/hooks/useDocumentOperations'

import { PUBLIC_ROUTE_RGX } from 'shared/constants/navigation'
import { useLocalization } from 'shared/providers/LocalizationProvider'

import { title } from './App.intl'
import { useLogger } from './hooks'

const AppResetPassword = dynamic(
  () => import('shared/components/AppResetPassword').then((mod) => mod.AppResetPassword),
  {
    ssr: false,
  }
)

const LocalizedYup = dynamic(
  () => import('shared/components/LocalizedYup').then((mod) => mod.LocalizedYup),
  {
    ssr: false,
  }
)

const MyAppComponent = (props: AppProps) => {
  useLogger()
  useDocumentSocialWebViewBrowser()

  useEffect(() => {
    window.APP_BUILD_DATE = process.env.APP_BUILD_DATE || ''
    window.APP_VERSION = process.env.APP_VERSION || ''
  }, [])

  const {
    models: { isReady },
  } = useLocalization()
  const { formatMessage } = useIntl()

  const { asPath } = useRouter()

  const isRestorePasswordPage = useMemo(() => PUBLIC_ROUTE_RGX.test(asPath), [asPath])

  return (
    <>
      {isReady && (
        <Head>
          <title>{formatMessage(title)}</title>
        </Head>
      )}

      {isRestorePasswordPage ? <AppResetPassword {...props} /> : <AppOnboarding {...props} />}

      <ReactQueryDevtools initialIsOpen={false} />

      <LocalizedYup />

      <SplashScreen />
    </>
  )
}

const MyApp = withCoreProviders(MyAppComponent)

export { MyApp }
