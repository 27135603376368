import { AppEnv } from './env.types'

export const getAppEnv = () => {
  if (process.env.APP_ENV === 'prod') {
    return AppEnv.PROD
  }

  return AppEnv.STAGE
}

export const getSentryEnv = () => {
  return process.env.SENTRY_ENV
}

export const getForerunnerApiUrl = () => {
  if (!process.env.NEXT_PUBLIC_FORERUNNER_API_URL) {
    throw new Error('Calorietracker api url is required')
  }

  return process.env.NEXT_PUBLIC_FORERUNNER_API_URL
}

export const getForerunnerApiKey = (): string => {
  if (!process.env.NEXT_PUBLIC_FORERUNNER_API_KEY) {
    throw new Error('Forerunner api key is required')
  }

  return process.env.NEXT_PUBLIC_FORERUNNER_API_KEY || ''
}

export const getTestaniaApiUrl = () => {
  if (!process.env.NEXT_PUBLIC_TESTANIA_API_URL) {
    throw new Error('Testania api url is required')
  }

  return process.env.NEXT_PUBLIC_TESTANIA_API_URL
}

export const getTestaniaApiKey = (): string => {
  if (!process.env.NEXT_PUBLIC_TESTANIA_API_KEY) {
    throw new Error('Testania api key is required')
  }

  return process.env.NEXT_PUBLIC_TESTANIA_API_KEY
}

export const getGoogleTagManagerContainerID = () => {
  return process.env.NEXT_PUBLIC_GTM_CONTAINER_ID
}

export const getIsHyrosEnabled = () => {
  return process.env.NEXT_PUBLIC_HYROS_ENABLED === 'true' || false
}
