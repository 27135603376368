import { useCallback } from 'react'

import { ScreenId } from 'shared/config/types'
import { useAuthUser } from 'shared/providers/AuthUserProvider'

import { usePreparePaymentFlow } from './usePreparePaymentFlow'
import { useRedirectToPage } from './useRedirectToPage'
import { useSendOnboardingEndEvent } from './useSendOnboardingEndEvent'

import { FlowManager } from '../helpers/FlowManager'
import { Subflow } from '../types'

type UseOpenNextPageArgs = {
  flowManager: FlowManager | null
  currentScreenId: ScreenId
}

export const useOpenNextPage = ({ flowManager, currentScreenId }: UseOpenNextPageArgs) => {
  const {
    models: { user },
  } = useAuthUser()

  const sendOnboardingEndEvent = useSendOnboardingEndEvent()
  const preparePaymentFlow = usePreparePaymentFlow()
  const redirectToPage = useRedirectToPage()

  return useCallback(
    (delay?: number, options?: { isPaidOverride?: boolean }) => {
      // passing isPaidOverride from payment pages, to circumvent stale user in closure
      const isPaid = options?.isPaidOverride ?? user?.is_paid
      const userID = user?.id
      const userData = { isPaid, userID }

      const nextScreenId = flowManager?.getNextScreenId(currentScreenId, userData)
      const nextScreenUrl = nextScreenId ? flowManager?.getScreenUrlById(nextScreenId) : ''

      const isLastOnboardingScreen = flowManager?.isLastElementInSubFlow(
        currentScreenId,
        userData,
        Subflow.Onboarding
      )

      if (isLastOnboardingScreen) {
        sendOnboardingEndEvent()
      }

      if (!nextScreenUrl) return

      nextScreenId && flowManager?.saveLastScreenId(nextScreenId)

      const initialPaymentScreen = flowManager?.getInitialPaymentScreen()

      if (nextScreenId === initialPaymentScreen?.id) {
        preparePaymentFlow()
      }

      redirectToPage({
        delay,
        currentScreenId,
        nextScreenUrl,
      })
    },
    [
      currentScreenId,
      flowManager,
      preparePaymentFlow,
      redirectToPage,
      sendOnboardingEndEvent,
      user?.id,
      user?.is_paid,
    ]
  )
}
