/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Platformenumrequest = (typeof Platformenumrequest)[keyof typeof Platformenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Platformenumrequest = {
  ios: 'ios',
  web: 'web',
} as const
