import Cookies from 'js-cookie'

import { BaseScreen, FlowConfig, PaymentScreen } from 'shared/api/testania'
import { post_checkout_flow } from 'shared/config/post-checkout-flow'
import { screensConfig } from 'shared/config/screens'
import { ScreenId } from 'shared/config/types'
import {
  FLOW_MANAGER_COOKIE_NAME_PREFIX,
  ONBOARDING_FINISHED_COOKIE_EXPIRATION_IN_HOURS,
  ONBOARDING_FINISHED_COOKIE_NAME,
  UPSELL_BOUGHT_COOKIE_NAME,
} from 'shared/providers/FlowManagerProvider/constants'
import {
  PaymentFlowObj,
  Subflow,
  SubflowItem,
  SubflowsMap,
  TransformConfigFn,
} from 'shared/providers/FlowManagerProvider/types'
import { addHours, getCurrentTimestamp } from 'shared/utils/date'
import {
  getCustomOnboardingFlow,
  getCustomPaymentFlow,
  patchPaymentFlow,
  textValueToFlow,
} from 'shared/utils/devtools'
import { getSentryEnv } from 'shared/utils/env'
import { getOnboardingExpirationDate } from 'shared/utils/onboarding'
import {
  getSessionStorageValue,
  removeSessionStorageValue,
  setSessionStorageValue,
} from 'shared/utils/sessionStorage'

import { defaultTransformConfig, transformPostCheckoutSubflow } from './index'

const LAST_SCREEN_ID_KEY = 'lastScreenId'

type UserData = {
  isPaid?: boolean
  userID?: string
}

interface ConstructorParams {
  config: FlowConfig | null
  transformConfig?: TransformConfigFn
}

export class FlowManager {
  public subflowsMap: SubflowsMap
  public subflowsSequence: Array<SubflowItem>
  private paymentFlowObj: PaymentFlowObj
  public startPage: BaseScreen | null
  public onboardingSteps: BaseScreen[] = []
  private branchName: string

  constructor({ config, transformConfig = defaultTransformConfig }: ConstructorParams) {
    if (!config) {
      throw new Error('Flow manager - config not specified!')
    }

    const customFlow = getCustomOnboardingFlow()
    const customPaymentFlow = getCustomPaymentFlow()

    if (customFlow && getSentryEnv() !== 'prod') {
      config.onboarding_flow = textValueToFlow(customFlow)
    }

    if (customPaymentFlow && getSentryEnv() !== 'prod') {
      config.payment_flow = patchPaymentFlow(config.payment_flow, customPaymentFlow)
    }

    const transformedConfig = transformConfig(config, screensConfig)

    this.startPage = transformedConfig.start_page
    this.branchName = transformedConfig.branch_name

    this.subflowsMap = {
      onboardingFlow: transformedConfig.onboarding_flow,
      paymentFlow: transformedConfig.payment_flow,
      postCheckoutFlow: transformPostCheckoutSubflow(post_checkout_flow, screensConfig),
    }

    this.paymentFlowObj = this.initPaymentFlowObj(transformedConfig.payment_flow)

    this.subflowsSequence = [Subflow.Onboarding, Subflow.Payment, Subflow.PostCheckout]

    this.onboardingSteps = this.getOnboardingSteps()
  }

  getScreenById(screenId: ScreenId): BaseScreen | PaymentScreen | null {
    if (this.startPage?.id === screenId) {
      return this.startPage
    }

    for (const subflowName of this.subflowsSequence) {
      const screen = this.subflowsMap[subflowName].find(({ id }) => id === screenId)
      if (screen) {
        return screen
      }
    }

    return null
  }

  getStartScreen(isUser: boolean, isPaidUser: boolean, isRegistrationFinalized: boolean) {
    if (isPaidUser && isRegistrationFinalized) {
      const thankYouPage = screensConfig.ob_funnel_done_thank_you

      const screen = this.subflowsMap.postCheckoutFlow.find(({ id }) => id === thankYouPage.id)

      return screen || this.startPage
    }

    if (isPaidUser && !isRegistrationFinalized) {
      const screen = this.subflowsMap.postCheckoutFlow.find(
        ({ id }) => id === screensConfig.ob_payment_success_register.id
      )

      return screen || this.startPage
    }

    const initialPaymentScreen = this.getInitialPaymentScreen()

    // if user opens funnel via link in email
    if (!this.startPage && isUser && initialPaymentScreen && this.isEmailBranch) {
      return initialPaymentScreen
    }

    const isOnboardingFinished = this.isOnboardingFinished()

    // if user already completed onboarding and opens funnel later
    if (isOnboardingFinished && isUser && initialPaymentScreen) {
      return initialPaymentScreen
    }

    const lastScreenId = this.getLastScreenId()

    if (lastScreenId) {
      return this.getScreenById(lastScreenId as ScreenId)
    }

    return this.startPage
  }

  private get isEmailBranch() {
    return this.branchName === 'email'
  }

  getScreenUrlById(screenId: string | ScreenId) {
    return `/${screenId}`
  }

  isOnboardingFinished() {
    return Boolean(getOnboardingExpirationDate())
  }

  static setOnboardingFinished() {
    const now = getCurrentTimestamp()
    const expirationDate = addHours(now, ONBOARDING_FINISHED_COOKIE_EXPIRATION_IN_HOURS)
    const cookieValue = expirationDate.toISOString()

    Cookies.set(ONBOARDING_FINISHED_COOKIE_NAME, cookieValue, {
      expires: expirationDate,
      sameSite: 'Lax',
      secure: global.location.protocol.includes('https'),
      path: '/',
    })
  }

  checkIsPaymentFlowInProgress(currentScreenId: ScreenId) {
    return Boolean(~this.subflowsMap.paymentFlow.findIndex(({ id }) => id === currentScreenId))
  }

  checkIsStartPageActive(currentScreenId: ScreenId) {
    return currentScreenId === this.startPage?.id
  }

  getNextScreenUrl(currentScreenId: ScreenId, userData: UserData) {
    const nextScreenId = this.getNextScreenId(currentScreenId, userData)

    if (!nextScreenId) {
      return null
    }

    return `/${nextScreenId}`
  }

  getNextScreenId(currentScreenId: ScreenId, userData: UserData) {
    const { isPaid } = userData

    if (currentScreenId && currentScreenId === this.startPage?.id) {
      const nextScreen = this.getSubflow(this.subflowsSequence[0])[0]

      return nextScreen.id
    }

    for (let i = 0; i < this.subflowsSequence.length; ++i) {
      const flowToCheck = this.getSubflow(this.subflowsSequence[i])
      const isPaymentFlow = this.subflowsSequence[i] === Subflow.Payment

      const indexInThisFlow = flowToCheck.findIndex(({ id }) => id === currentScreenId)

      if (!~indexInThisFlow) {
        continue
      }

      const isLastElementOfFlow = isPaymentFlow
        ? !this.getNextPaymentScreenId(currentScreenId, userData)
        : indexInThisFlow === flowToCheck.length - 1

      if (isLastElementOfFlow) {
        if (i === this.subflowsSequence.length - 1) {
          return undefined
        }

        const nextFlowInSequence = this.subflowsSequence[i + 1]

        if (nextFlowInSequence === Subflow.Payment) {
          const initialPaymentScreen = this.getInitialPaymentScreen()

          if (!isPaid && initialPaymentScreen.id !== currentScreenId) {
            return initialPaymentScreen.id
          }

          const nextPaymentScreenId = this.getNextPaymentScreenId(
            initialPaymentScreen.id as ScreenId,
            userData
          )

          if (nextPaymentScreenId) {
            return nextPaymentScreenId
          }

          const secondFlowInSequence = this.subflowsSequence[i + 2]
          const firstElementOfSecondFlow = this.getSubflow(secondFlowInSequence)[0]

          return firstElementOfSecondFlow.id
        }

        const firstElementOfNextFlow = this.getSubflow(nextFlowInSequence)[0]

        return firstElementOfNextFlow.id
      }

      return isPaymentFlow
        ? this.getNextPaymentScreenId(currentScreenId, userData)
        : flowToCheck[indexInThisFlow + 1].id
    }

    return undefined
  }

  isLastElementInSubFlow(currentScreenId: ScreenId, userData: UserData, subFlow: Subflow) {
    const flowToCheck = this.getSubflow(subFlow)
    const isPaymentFlow = subFlow === Subflow.Payment

    const indexInThisFlow = flowToCheck.findIndex(({ id }) => id === currentScreenId)

    if (indexInThisFlow === -1) {
      return false
    }

    return isPaymentFlow
      ? !this.getNextPaymentScreenId(currentScreenId, userData)
      : indexInThisFlow === flowToCheck.length - 1
  }

  getInitialPaymentScreen() {
    const initialPaymentScreen = this.subflowsMap.paymentFlow.find(
      ({ parent_id }) => parent_id === null
    )

    if (!initialPaymentScreen) {
      throw new Error('Flow manager - initial payment screen is not specified!')
    }

    return initialPaymentScreen
  }

  static clearCookies() {
    const flowManagerCookieList = this.getFlowManagerCookieList(global.document.cookie)

    for (const cookieName of flowManagerCookieList) {
      Cookies.remove(cookieName)
    }

    removeSessionStorageValue(LAST_SCREEN_ID_KEY)
  }

  static getFlowManagerCookieList(cookieStr: string) {
    const cookies = cookieStr.split('; ')

    return cookies
      .map((cookie) => {
        const [cookieName] = cookie.split('=')

        return cookieName
      })
      .filter((cookieName: string) => cookieName.startsWith(FLOW_MANAGER_COOKIE_NAME_PREFIX))
  }

  private getNextPaymentScreenId(
    currentScreenId: ScreenId,
    userData: UserData
  ): string | undefined {
    const { isPaid } = userData
    const flow = this.paymentFlowObj[currentScreenId]

    const screen = flow?.find(({ is_paid }) => is_paid === Boolean(isPaid))

    if (screen?.id.match('upsell')) {
      const isUpsellBought = Cookies.get(UPSELL_BOUGHT_COOKIE_NAME) === screen.name

      if (isUpsellBought) {
        return this.getNextPaymentScreenId(screen.id as ScreenId, userData)
      }
    }

    return screen?.id
  }

  private initPaymentFlowObj(paymentFlow: PaymentScreen[]) {
    return paymentFlow.reduce((accum, { id }) => {
      const children: PaymentScreen[] = []

      for (let i = 0; i < paymentFlow.length; i++) {
        if (paymentFlow[i].parent_id === id) {
          children.push(paymentFlow[i])
        }
      }

      if (children.length) {
        accum[id] = children
      }

      return accum
    }, {} as PaymentFlowObj)
  }

  private getOnboardingSteps() {
    const onboardingSubflow = this.subflowsMap[Subflow.Onboarding]

    return onboardingSubflow.filter(({ config }) => {
      if (typeof config === 'object') {
        return (config as { step?: string })['step'] || false
      }

      return false
    })
  }

  private getSubflow = (subflowName: Subflow) => {
    return this.subflowsMap[subflowName]
  }

  getLastScreenId() {
    return getSessionStorageValue(LAST_SCREEN_ID_KEY)
  }

  saveLastScreenId(screenId: string) {
    setSessionStorageValue(LAST_SCREEN_ID_KEY, screenId)
  }
}
