import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import Head from 'next/head'

import { AuthTokenProvider } from 'shared/providers/AuthTokenProvider'
import { LocalizationProvider } from 'shared/providers/LocalizationProvider'
import { ThemeProvider } from 'shared/providers/ThemeProvider'

// create react-query client
const queryClient = new QueryClient()

export function withCoreProviders(WrappedComponent: (props: AppProps) => JSX.Element) {
  return (props: AppProps): JSX.Element => {
    const { Component, pageProps } = props

    if (pageProps.err)
      return (
        <ThemeProvider defaultTheme="default">
          <Component {...pageProps} />
        </ThemeProvider>
      )

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta property="og:type" content="website" key="og-type"></meta>
          <meta property="og:title" content="Mindea: Psychology & Nutrition" key="og-title"></meta>
          <meta property="og:image" content={process.env.META_OG_IMAGE_URL} key="og-image"></meta>
          <meta property="og:image:width" content="1200" key="og-image-width"></meta>
          <meta property="og:image:height" content="630" key="og-image-height"></meta>
        </Head>
        <ThemeProvider defaultTheme="default">
          <AuthTokenProvider>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider>
                <WrappedComponent {...props} />
              </LocalizationProvider>
            </QueryClientProvider>
          </AuthTokenProvider>
        </ThemeProvider>
      </>
    )
  }
}
