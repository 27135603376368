import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { useLocation } from 'shared/hooks/useLocation'

import { IS_DEVTOOLS_AVAILABLE } from 'shared/constants/devtools'
import { withTheme } from 'shared/hocs/withTheme'
import { AnalyticsProvider } from 'shared/providers/AnalyticsProvider'
import { AnalyticsAdapter } from 'shared/providers/AnalyticsProvider/helpers/AnalyticsAdapter'
import { AuthUserProvider } from 'shared/providers/AuthUserProvider'
import { ClientDataProvider } from 'shared/providers/ClientDataProvider'
import { FlowLoader } from 'shared/providers/FlowLoader'
import { FlowManagerProvider } from 'shared/providers/FlowManagerProvider'
import { OneTrustProvider } from 'shared/providers/OneTrustProvider'
import { PaymentProvider, SolidPaymentPreloader } from 'shared/providers/PaymentProvider'

import { onLoad } from './helpers'

const Devtools = dynamic(() => import('shared/components/Devtools').then((mod) => mod.Devtools), {
  ssr: false,
})

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Container = withTheme((props: ContainerProps) => {
  return <StyledContainer {...props} />
})

// create instance of analytics service
const AnalyticsService = new AnalyticsAdapter()
const ONE_TRUST_ID = process.env.NEXT_PUBLIC_ONE_TRUST_DOMAIN_ID

export const AppOnboarding = ({ Component, pageProps }: AppProps) => {
  const { country } = useLocation()

  return (
    <AuthUserProvider>
      <FlowLoader onLoad={onLoad}>
        <OneTrustProvider id={ONE_TRUST_ID} withAutoBlock>
          <AnalyticsProvider service={AnalyticsService} country={country}>
            <ClientDataProvider country={country}>
              <PaymentProvider>
                <FlowManagerProvider currentScreenId={pageProps.id}>
                  {IS_DEVTOOLS_AVAILABLE && <Devtools />}
                  <Container>
                    <Component {...pageProps} />
                  </Container>
                </FlowManagerProvider>
                <SolidPaymentPreloader />
              </PaymentProvider>
            </ClientDataProvider>
          </AnalyticsProvider>
        </OneTrustProvider>
      </FlowLoader>
    </AuthUserProvider>
  )
}
