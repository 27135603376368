import React from 'react'

// reducer
import { UnitsSystem } from './constants/UnitsSystem'
import { getClientData, saveClientData } from './helper'
import { INITIAL_STATE, reducer } from './reducer'
import { Dispatch, State } from './types'

const IMPERIAL_SYSTEM_COUNTRIES = process.env.NEXT_PUBLIC_IMPERIAL_SYSTEM_COUNTRIES || ''

export const ClientDataStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

/**
 * Provides api to child components via hooks api.
 */
export const useClientData = () => {
  const context = React.useContext(ClientDataStateContext)

  if (!context) {
    throw new Error(`useClientData must be used within ClientDataProvider`)
  }

  return context
}

/**
 * ClientDataProvider component props
 */
interface ClientDataProviderProps {
  /**
   * The child nodes ClientDataProvider has wrapped
   */
  children?: React.ReactNode
  /**
   * Country by user's location
   */
  country: string | null
}

export const ClientDataProvider = ({ country, children }: ClientDataProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE, () =>
    getClientData(INITIAL_STATE)
  )

  React.useEffect(() => {
    country && dispatch({ type: 'SET_COUNTRY', payload: country })

    if (country && IMPERIAL_SYSTEM_COUNTRIES.includes(country)) {
      dispatch({ type: 'SET_UNIT_SYSTEM', payload: UnitsSystem.IMPERIAL })
    }
  }, [country, dispatch])

  React.useEffect(() => saveClientData(state), [state])

  const value = React.useMemo(() => ({ state, dispatch }), [state])

  return <ClientDataStateContext.Provider value={value}>{children}</ClientDataStateContext.Provider>
}
