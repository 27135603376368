import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useIntl } from 'react-intl'

import { useFlowLoader } from 'shared/providers/FlowLoader/FlowLoader'
import { getAppEnv, getIsHyrosEnabled } from 'shared/utils/env'
import { getUrlParams, urlParamsToObject } from 'shared/utils/url-params'

import { SYSTEMS_NAMES } from './analytics/Constants'
import { getInitialShouldBeDisabled, sanitizeQueryParams } from './helpers'
import { AnalyticsAdapter } from './helpers/AnalyticsAdapter'

interface AnalyticsContextInterface {
  setUserProperties: AnalyticsAdapter['setUserProperties']
  logEvent: AnalyticsAdapter['logEvent']
  clearEventsPool: AnalyticsAdapter['clearEventsPool']
}

const AnalyticsContext = createContext<AnalyticsContextInterface | null>(null)

function useAnalytics() {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error(`useAnalytics must be used within AnalyticsProvider`)
  }

  return context
}

interface AnalyticsProviderProps extends PropsWithChildren {
  service: AnalyticsAdapter
  country: string | null
  amazonOnly?: boolean
}

interface ExternalAnalyticsProps extends AnalyticsProviderProps {}

interface AnalyticsApiProviderProps extends PropsWithChildren {
  service: AnalyticsAdapter
}

const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const { service, children, country, amazonOnly } = props
  const intl = useIntl()
  const [inited, setInited] = useState(false)
  const [activated, setActivated] = useState(false)
  const shouldBeDisabled = useMemo(getInitialShouldBeDisabled, [])

  // initialize analytics service
  useEffect(() => {
    service.init().then(() => {
      setActivated(false)
      setInited(true)
    })

    return () => {
      service.reset()
      service.clearEventsPool()
    }
  }, [service])

  // save user's country to user properties
  useEffect(() => {
    if (country) {
      service.setUserProperties({
        country,
      })
    }
  }, [service, country])

  // save user's locale to user properties
  useEffect(() => {
    service.setUserProperties({
      locale: intl.locale,
    })
  }, [service, intl.locale])

  // save query params to user properties
  useEffect(() => {
    const oldUrlParams = getUrlParams()
    const urlSearchParams = new URLSearchParams(window.location.search)
    const newUrlParams = sanitizeQueryParams(urlParamsToObject(urlSearchParams))

    // send new query params if they are not empty otherwise send persisted value from local storage
    service.setUserProperties({
      urlParams:
        Object.keys(newUrlParams).length !== 0 ? JSON.stringify(newUrlParams) : oldUrlParams,
    })
  }, [service])

  useEffect(() => {
    if (inited && country && !activated && !shouldBeDisabled) {
      const appEnv = getAppEnv()

      const analytics: any[] = [
        {
          name: SYSTEMS_NAMES.gtmDataLayer,
        },
      ]

      if (getIsHyrosEnabled()) {
        analytics.push({
          name: SYSTEMS_NAMES.hyrosDataLayer,
        })
      }

      if (process.env.NEXT_PUBLIC_AWS_ID) {
        analytics.push({
          name: SYSTEMS_NAMES.amazon,
          id: process.env.NEXT_PUBLIC_AWS_ID,
          config: {
            streamName: process.env.NEXT_PUBLIC_AWS_DELIVERY_STREAM,
            envMode: appEnv,
            releaseDate: `${global.window.APP_BUILD_DATE?.substring(0, 10)}`,
          },
        })
      }

      if (analytics.length) {
        service.activateEventSending(analytics)
      }

      setActivated(true)
    }
  }, [inited, country, service, activated, shouldBeDisabled])

  if (amazonOnly) {
    return <AnalyticsApiProvider service={service}>{children}</AnalyticsApiProvider>
  }

  return <ExternalAnalytics {...props}>{children}</ExternalAnalytics>
}

const ExternalAnalytics = (props: ExternalAnalyticsProps) => {
  const { service, children } = props
  const {
    models: { config },
  } = useFlowLoader()

  // save abTestName to user properties
  useEffect(() => {
    if (config?.flow_name) {
      service.setUserProperties({
        abTestName: config?.flow_name,
      })
    }
  }, [service, config])

  return <AnalyticsApiProvider service={service}>{children}</AnalyticsApiProvider>
}

const AnalyticsApiProvider: React.FC<AnalyticsApiProviderProps> = ({ service, children }) => {
  // provide api to components
  const api = useMemo<AnalyticsContextInterface>((): AnalyticsContextInterface => {
    return {
      setUserProperties: service.setUserProperties,
      logEvent: service.logEvent,
      clearEventsPool: service.clearEventsPool,
    }
  }, [service])

  return <AnalyticsContext.Provider value={api}>{children}</AnalyticsContext.Provider>
}

export { AnalyticsProvider, useAnalytics }
