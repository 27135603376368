import { BaseScreen, PaymentScreen, FlowConfig } from 'shared/api/testania'
import { screensConfig } from 'shared/config/screens'

export enum Subflow {
  Onboarding = 'onboardingFlow',
  Payment = 'paymentFlow',
  PostCheckout = 'postCheckoutFlow',
}

export type SubflowItem = (typeof Subflow)[keyof typeof Subflow]

export interface SubflowsMap {
  [Subflow.Onboarding]: FlowConfig['onboarding_flow']
  [Subflow.Payment]: FlowConfig['payment_flow']
  [Subflow.PostCheckout]: BaseScreen[]
}

export type PaymentFlowObj = Record<string, PaymentScreen[]>

export type TransformConfigFn = (config: FlowConfig, screensMap: typeof screensConfig) => FlowConfig
