import Router from 'next/router'
import React from 'react'

import { UserModel } from 'shared/providers/AuthUserProvider'
import { AuthStatus } from 'shared/providers/AuthUserProvider/constants'
import { FlowManager } from 'shared/providers/FlowManagerProvider/helpers/FlowManager'
import { logger } from 'shared/utils/logger'

interface UseInitialNavigationParams {
  flowManager: FlowManager | null
  allowAllCookies: () => void
  resetABTest: () => void
  user: UserModel | null
  authStatus: AuthStatus
}

export function useInitialNavigation({
  flowManager,
  allowAllCookies,
  resetABTest,
  authStatus,
  user,
}: UseInitialNavigationParams): boolean {
  const [isCompleted, setIsCompleted] = React.useState(false)

  React.useEffect(() => {
    if (!flowManager || authStatus !== AuthStatus.DONE || isCompleted) {
      return
    }

    const startScreen = flowManager.getStartScreen(
      Boolean(user),
      Boolean(user?.is_paid),
      Boolean(user?.is_finalized)
    )

    if (!startScreen) {
      logger.warn('startScreen not found')
      FlowManager.clearCookies()
      resetABTest()
      window.location.replace('/')

      return
    }

    const initialPaymentScreen = flowManager.getInitialPaymentScreen()

    if (startScreen.id === initialPaymentScreen.id) {
      const isOnboardingFinished = flowManager.isOnboardingFinished()

      if (!isOnboardingFinished) {
        FlowManager.setOnboardingFinished()
      }
    }

    const startScreenUrl = flowManager.getScreenUrlById(startScreen.id)

    const queryString = new URLSearchParams(global.window.location.search).toString()
    const sign = queryString ? '?' : ''
    const url = `${startScreenUrl}${sign}${queryString}`

    Router.replace(url)
      .then(() => {
        setIsCompleted(true)
      })
      .catch((err) => {
        logger.info(`Initial navigation error: start screen url - ${startScreenUrl}`)
        logger.error(Error('[router] Navigation error', { cause: err }))
      })
  }, [isCompleted, flowManager, allowAllCookies, user, resetABTest, authStatus])

  return isCompleted
}
